import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import './../assets/css/souscription.css'

import strokeBox from './../assets/img/stroke_box.png'
import vehicles from './../assets/img/vehicles.svg'
import hospital from './../assets/img/hospital.svg'

const Souscription = () => {
  return (
    <>
      <Helmet>
        <title>Souscription | Paramedic</title>
      </Helmet>
      <section id='main_souscription'>
        <div className='container title' data-aos='fade-down' data-aos-duration='1500'>
          <h1 id='head_title'>Souscription</h1>
          <p>Je suis...</p>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-2 col-md-0' />
            <div className='col-xl-3 col-md-5 box box-left' data-aos='fade-right' data-aos-duration='1500'>
              <h6>Établissement de santé</h6>
              <img draggable='false' src={strokeBox} className='stroke' alt='Trait blanc.' />
              <p>Je souhaite bénéficier d’un service de gestion des transports sanitaires</p>
              <Link to='/form_sante' className='btn'>Je me lance</Link>
              <div className='row'>
                <img
                  draggable='false' src={hospital} className='illustration'
                  alt="Illustration d'une ambulance et d'un taxi."
                />
              </div>
            </div>
            <div className='col-xl-3 col-md-5 box box-right' data-aos='fade-left' data-aos-duration='1500'>
              <h6>Société de transport</h6>
              <img draggable='false' src={strokeBox} className='stroke' alt='Trait blanc.' />
              <p>Je souhaite obtenir des transports supplémentaires et adaptés à ma zone d’exercice <br /><br /></p>
              <div className='dropdown'>
                <Link
                  className='btn btn-secondary' to='/transport' role='button' id='dropdownMenuLink' data-toggle='dropdown'
                  aria-haspopup='true' aria-expanded='false'
                >
                  Je me lance
                </Link>

                <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                  <Link className='dropdown-item' to='/form_taxi'>Taxi</Link>
                  <Link className='dropdown-item' to='/form_ambulance'>Ambulance</Link>
                </div>
              </div>
              {/* <a href="form_transport.html">Je me lance</a> */}
              <div className='row'>
                <img
                  draggable='false' src={vehicles} className='illustration vehicles'
                  alt="Illustration d'une ambulance et d'un taxi."
                />
              </div>
            </div>
            <div className='col-xl-2 col-md-0' />
          </div>
        </div>
      </section>
    </>
  )
}

export default Souscription
